/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import SendTitleCompany from "./SendTitleCompany"
import { Col, Modal, Row, Button,Collapse, message, Spin, Tabs, Input, Form, } from "antd";
import { FolderFilled, FolderOpenOutlined, FileZipOutlined, CaretRightOutlined, CaretDownOutlined,
 ExclamationCircleOutlined, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { IoIosCreate } from "react-icons/io";
import { MdOutlineUploadFile, } from "react-icons/md";
import { TitleCompanyDetails } from "./TitleCompanyDetails";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import {  useLocation } from "react-router-dom";
import styles from "../style/document.module.css";
import _ from "lodash";
import TitleCompanyDocument from "./TitleCompanyDocument";
import axios from "axios";
import { checkIfDisabled, createOfferContractTypes, disabledTransactionText } from "../../Common/commondata/commonData";
import { filteredTransactionData, removePersonObject } from "../../PropertyComp/components/helper";
import UploadPdfDoc from "../../PropertyComp/components/UploadPdfDoc";
import { formatPhoneNumber, getCoAgentsIds } from "../../Common/utils/extraFunctions";

const { Panel } = Collapse;

const StyledTree = styled.div`
  line-height: 1.9;
`;
const StyledFile = styled.div`
  padding-left: px;
  display: flex;
  align-items: center;
  span {
    margin-left: 0px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 0px;
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 0px;
    }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "0" : "auto")};
  overflow: hidden;
`;

const File = ({ doraDocItem, selectedData, seller, getTransactionLaneData, insideFolder, section}) => {
    let delegate = (section === "DELEGATE");
    const { getDoraDocumentsData} = useSelector((state) => state.rtdTransactionLane);
    let dispatch = useDispatch();
  
    const currentAgentDetails = selectedData?.currentAgentId;

    const [modalVisible, setModalVisible] = useState({
        isModalVisible:false,
        isEmailModalVisible:false,
        isEmailPresent:false,
        isAllEmailsModalOpen:false,
    });
    const [allData, setAllData] = useState({
      uploadPdfDoc: false,
      file: "",
      ...(delegate
        ? {
            delegateSide: seller === "SELLER" ? "SELLER" : "BUYER",
            delegateOwnerId: currentAgentDetails?._id,
          }
        : {}),
    });


    const location = useLocation();

    const handleCancel = () => {
        setModalVisible({...modalVisible,isModalVisible:false})
    };

    const {getTitleCompanySelectedData} = useSelector((state)=>state.rtdTransactionLane);
    const urlShortHand = (ele) => {
      selectedData = filteredTransactionData({ ele, selectedData });
      let buildData = {
        builtForId: currentAgentDetails?._id,
        key: currentAgentDetails?._id,
        builtForSection: "DOCUMENT",
        signable: true,
        openFor: seller === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
        contractId: ele.contractId,
        contractType: ele.contractType,
        offerId: selectedData?.offerId,
        propertyId: selectedData?.propertyId,
        token: localStorage.getItem("userAuth"),
        RTDInitiator: seller === "SELLER" ? "SELLER" : "BUYER",
        buyerAgentIds: getCoAgentsIds(selectedData?.buyerAgentIds),
        sellerAgentIds: getCoAgentsIds(selectedData?.sellerAgentIds),
        ...(selectedData?.thirdpartyId ? { thirdpartyId: selectedData?.thirdpartyId } : {}),
        ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
        urlData: {
          ...(selectedData.newTransactionData ? { ...selectedData.newTransactionData } : {}),
          ...(ele?.prefillValues || {}),
          Buyers: removePersonObject(selectedData?.buyerIds),
          Sellers: removePersonObject(selectedData?.sellerIds),
          ...((ele?.contractType === "CI" || ele?.contractType === "TO") && getTitleCompanySelectedData
            ? {
                TitleCompanyLogo: getTitleCompanySelectedData.titleCompanyLogo || "",
                TitleCompanyName: getTitleCompanySelectedData.titleCompanyName || "",
                TitleOfficeName: getTitleCompanySelectedData.titleOfficeName || "",
                TitleOfficeEmail: getTitleCompanySelectedData.titleOfficeEmail || "",
                TitleOfficeAddress: getTitleCompanySelectedData.titleOfficeAddress || "",
                TitleOfficePhoneNo: getTitleCompanySelectedData.titleOfficePhoneNo || "",
                TitleOfficeFaxNo: getTitleCompanySelectedData.titleOfficeFaxNo || "",
                TitleCloserName: getTitleCompanySelectedData.titleCloserName || "",
                TitleCloserEmail: getTitleCompanySelectedData.titleCloserEmail || "",
                SalesRepName: getTitleCompanySelectedData.salesRepName || "",
                SalesRepEmail: getTitleCompanySelectedData.salesRepEmail || "",
              }
            : {}),
          ...(ele?.contractType === "SA"
            ? {
                BrokerName: currentAgentDetails?.fullName || "",
                BrokerEmailAddress: currentAgentDetails?.email || "",
                BrokerFirmName: currentAgentDetails?.brokerageData?.name || "",
                BrokerFirmAddress: currentAgentDetails?.brokerageData?.address || "",
                BrokerPhoneNumber: formatPhoneNumber(currentAgentDetails?.mobilePhoneNumber?.phoneNumber),
                BrokerFaxNumber: formatPhoneNumber(currentAgentDetails?.brokerageData?.faxNumber),
              }
            : {}),
        },

        ...((ele?.contractType === "CI" || ele?.contractType === "TO") && getTitleCompanySelectedData
          ? {
              titleCompanyDetails: {
                inBuilt: getTitleCompanySelectedData.inBuilt,
                selectedBy: getTitleCompanySelectedData.selectedBy,
                ...(getTitleCompanySelectedData.titleOfficeId ? { titleOfficeId: getTitleCompanySelectedData.titleOfficeId } : {}),
                ...(getTitleCompanySelectedData.salesRepId ? { salesRepId: getTitleCompanySelectedData.salesRepId } : {}),
                ...(getTitleCompanySelectedData.titleCloserId ? { titleCloserId: getTitleCompanySelectedData.titleCloserId } : {}),
              },
            }
          : {}),
      };
      dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname, delegateOwnerId: delegate && currentAgentDetails?._id }));
    };
    let docInRtd = false;
    getTransactionLaneData?.forEach((el) => {
        if (el.contractType === doraDocItem.contractType) {
        docInRtd = true;
        }
    });

    const handleCreateNew = () => {

        if(((doraDocItem?.contractType === "TO") || (doraDocItem?.contractType === "CI")) && (!getTitleCompanySelectedData?.selectedBy)){
            message.error("Please select/add a Title Company to proceed with the following document!");
            return;
        }
        if((doraDocItem?.contractType === "INSP_RESOL") && (getTransactionLaneData?.filter((el) => el?.contractType === "INSP_OBJ")?.length <= 0)){
            Modal.confirm({
                title:"Warning! An inspection objection document should exist before creating an inspection resolution document! Are you sure you want to proceed?",
                okText:"Yes",
                cancelText:"No",
                icon: <ExclamationCircleOutlined />,
                onOk: () => {
                    urlShortHand(doraDocItem)
                },
                onCancel: () => {
                    handleCancel()
                }
            })
        }else{
            urlShortHand(doraDocItem)
        }
       
 }
   
  
    useEffect(() => {
        if(seller === "BUYER"){
            let data = selectedData?.newTransactionData?.Buyers?.filter((el) => el.personId === null);
            if (data?.length > 0) {
            setModalVisible({...modalVisible,isEmailPresent:false})
            }else{
            setModalVisible({...modalVisible,isEmailPresent:true})
            }
        }else{
            let data = selectedData?.newTransactionData?.Sellers?.filter((el) => el.personId === null);
            if (data?.length > 0) {
            setModalVisible({...modalVisible,isEmailPresent:false})
            }else{
            setModalVisible({...modalVisible,isEmailPresent:true})
            }
        }
    }, [selectedData]);
    const showConfirmATAEModal=({message})=>{
        Modal.confirm({
        title: message,
        content: "",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
            setModalVisible({...modalVisible, isModalVisible:true})
        },
        onCancel() {},
        });
    }

    const hasActiveCBSR = getTransactionLaneData?.some(el => createOfferContractTypes.includes(el.contractType));
    const handleDocNameClick = ({doraDocItem}) => {
        // let documentName = "";
        let contractType = "";
        getDoraDocumentsData?.forEach(doc => {
            if (createOfferContractTypes.includes(doc.contractType)) {
            //   documentName = doc.name;
              contractType = doc.contractType;
            }
          });
        if (!hasActiveCBSR) {
            message.error(`To add another document, you must have an active ${contractType} in the Transaction Dashboard.`)
        }
   
      if (["MH_ATAE", "MH_ATAES"].includes(doraDocItem.contractType)) {
                let foundMHOffer = getTransactionLaneData?.some(el => el.contractType === "CBS_MH");
                if (!foundMHOffer) {
                    let message=`This transaction document is for Manufactured Home. Are you sure you don't want to use an Amend Extend document instead?`
                    showConfirmATAEModal({ message });
                    return;
                }
            } else if (["ATAE", "ATAES"].includes(doraDocItem.contractType)) {
                let foundMHOffer = getTransactionLaneData?.some(el => el.contractType === "CBS_MH");
                if (foundMHOffer) {
                    let message=`This transaction document is not for Manufactured Home. Are you sure you don't want to use a Manufactured Home Amend/Extend document instead?`
                    showConfirmATAEModal({ message })
                    return
                }
            }
            setModalVisible({...modalVisible,isModalVisible:true})
    }

    const checkForDisable = checkIfDisabled({openFor: seller, actualPropertyStatus: selectedData?.actualPropertyStatus, buyingStatus: selectedData?.buyingStatus, offerStatus: selectedData?.status, enableArray: doraDocItem?.enableDuringDisableStatus});

    const handleUploadNew = () => {
        setAllData({
            ...allData,
            uploadPdfDoc: true,
        })
    }

    const handleModalCancel = () => {
        setAllData({
            ...allData,
            uploadPdfDoc: false
        })
    }

    return (
      <StyledFile>
        <span
          style={{
            fontSize: "18px",
            fontWeight: 500,
            color: docInRtd && "rgb(110 153 214)" || "rgb(176, 176, 176)",
            width: "100%",
            marginTop: "4px",
            marginLeft: insideFolder ? "21px" : "auto",
            paddingLeft: 0,
            pointerEvents: createOfferContractTypes.includes(doraDocItem?.contractType) ? "none" : "pointer",
            cursor: createOfferContractTypes.includes(doraDocItem?.contractType) ? "default" : "pointer",
          }}
        >
          <FileZipOutlined style={{ fontSize: "20px" }} />
          <span
            className={styles.documentsName}
            {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => handleDocNameClick({ doraDocItem })})}
          >
            {doraDocItem?.name}
          </span>
        </span>
        <UploadPdfDoc selectedData={{...selectedData, ...doraDocItem, RTDInitiator: seller}} allData={allData} setAllData={setAllData} handleModalCancel={handleModalCancel} open={allData?.uploadPdfDoc}/>
        <Modal visible={hasActiveCBSR && modalVisible.isModalVisible} onCancel={handleCancel} footer={[]}>
          {selectedData?.propertyYearBuilt > 1977 && (doraDocItem.contractType === "LBPOS" || doraDocItem.contractType === "LBPD") && (
            <p style={{ fontWeight: "500", color: "#2976be" }}>
              Note : <text style={{ fontWeight: "500", color: "darkgray" }}>Document Not Required ! This property was built after 1977, exempting the need for this document.</text>{" "}
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              gap: "5px",
              width: "51%",
              margin: "auto",
              paddingLeft: "30px",
            }}
          >
            <p
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "20px",
                color: "#2976be",
                cursor: "pointer",
              }}
              onClick={handleCreateNew}
            >
              <IoIosCreate size={25} /> Create New
            </p>
            <p
                style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                fontSize: "20px",
                color: "#2976be",
                cursor: "pointer",
                }}
                onClick={handleUploadNew}
            >
                <MdOutlineUploadFile size={25} /> Upload PDF
            </p>
          </div>
        </Modal>
     </StyledFile>
    );
};

// its an folder structure component
const Folder = ({ name, children, selectedData, fiters }) => {
    const [isOpen, setIsOpen] = useState(fiters?.search ? true : false);
    const handleToggle = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const archivedOrTerminated = selectedData?.status === "TERMINATED"
    return (
        <StyledFolder>
        <div
            className="folder--label"
            onClick={handleToggle}
            style={{
            cursor: "pointer",
            pointerEvents: archivedOrTerminated ? "none" : "pointer",
            alignItems: 'baseline',
            }}
        >
            {isOpen ? (
            <>
                <CaretDownOutlined
                style={{
                    fontSize: "1.3rem",
                    marginRight: "5px",
                    color: archivedOrTerminated ? "#b0b0b0" : "rgb(107,152,199)",
                }}
                />{" "}
                <FolderOpenOutlined
                style={{ fontSize: "20px", color: "rgb(107,152,199)" }}
                />
            </>
            ) : (
            <>
                <CaretRightOutlined style={{ fontSize: "1.3rem", color: "#8C8C8C" }} />
                <FolderFilled
                style={{
                    fontSize: "20px",
                    color: archivedOrTerminated ? "#b0b0b0" : "rgb(107,152,199)",
                    // marginLeft: "14px",
                }}
                />
            </>
            )}
            <span
            style={{
                fontSize: "19px",
                fontWeight: 600,
                color: "#8C8C8C",
                marginTop: "4px",
                paddingLeft: "4px",
            }}
            >
            {name}
            </span>
        </div>
        <Collapsible isOpen={!isOpen}>{children}</Collapsible>
        </StyledFolder>
    );
};

const Tree = ({ children }) => {
  return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
Tree.Folder = Folder;

export default function DoraDocument({ seller, showDrawer, section,propertyDocs }) {
    const delegate = ( section === "DELEGATE");
    const { getTransactionLaneData, getIndividualTransactionData, getDoraDocumentsData, getDoraDocumentsLoading, getDoraDocumentsError, getTitleCompanySelectedData, getTitleCompanyDataLoading, getTitleCompanyDataError, refreshTitleCompany} = useSelector((state) => state.rtdTransactionLane);
    const [activeKey, setActiveKey] = useState("1")
    const dispatch = useDispatch();
    const [showcompanymodal, setShowcompanymodal] = useState(false);
    const [sendCompanyModal,setSendCompanyModal]=useState(false);
    const [fiters, setFilters] = useState({search: ""});
    const [form] = Form.useForm();
   
    const delegateSide = delegate && seller;
 

    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(()=>{
        if(showDrawer){
            let data = {
                listType: seller,
                offerId: getIndividualTransactionData.offerId,
            };
            dispatch(transactionLaneDataHandle.getDoraDocumentsData({data, fiters, source, unmounted}));

            return () => {
                unmounted.value = true;
                source.cancel("Cancelling in cleanup");
            }
        }
    }, [showDrawer, fiters])

    useEffect(()=>{
        if(showDrawer && ((getIndividualTransactionData?.status === "ACCEPTED") || (getIndividualTransactionData?.status === "TERMINATED") || (getIndividualTransactionData?.status === "DRAFT") || (getIndividualTransactionData?.status === "SUBMITTED"))){
            dispatch(transactionLaneDataHandle.getTitleCompanyData({offerId: getIndividualTransactionData.offerId, source, unmounted, delegateSide}));

            return () => {
                unmounted.value = true;
                source.cancel("Cancelling in cleanup");
            }
        }
    }, [showDrawer, refreshTitleCompany])


    const handleOpenCompanyModal = () => {
        setShowcompanymodal(true);
    };
    const handleInnerClick = (e) => {
        e.stopPropagation(); // Prevent the click event from propagating to the outer component
        setSendCompanyModal(true);
    };

    function totratlast() {
        let arr = _.cloneDeep(getDoraDocumentsData);
        for (let i = 0; i < arr.length; i++) {
        if (arr[i].contractType === "TO") {
            arr.push(...arr.splice(i, 1));
        }
        if (arr[i].contractType === "TR") {
            arr.push(...arr.splice(i, 1));
        }
        }
        return arr;
    }

/* <--delete title company data---> */
    const {confirm}=Modal;
    const handeldeleteTitleCompanydata=()=>{
        confirm({
        title: `Are you sure, you want to clear Title Company ?`,
        content: "Note: Clearing the title company will clear title company information from the Title Order and Closing Instructions form. If Closing Instructions has been signed and not sent to other broker, signatures will be reset. If Closing Instructions has been signed and has been sent to other broker the title company won't be cleared in those document.",
        okText: "Yes",
        cancelText: "No",
        icon: <ExclamationCircleOutlined />,
        onOk() {
            dispatch(transactionLaneDataHandle.deleteTitleCompanyData({offerId: getIndividualTransactionData?.offerId, delegateSide}))
        },
        onCancel() {},
        });
    }

    const handleOpen=()=>{
        handleOpenCompanyModal()
    }

    const handleChange = (value) => {
        setActiveKey(value);
    }

    const checkForDisable = checkIfDisabled({openFor: seller, actualPropertyStatus: getIndividualTransactionData?.actualPropertyStatus, buyingStatus: getIndividualTransactionData?.buyingStatus,  offerStatus: getIndividualTransactionData?.status});

    const handleClear = ({ renderWithClear }) => {
        form.resetFields();
        setFilters({
            search: ""
        })
    }

    //debounce logic to call search api
    const debouncedFunction = _.debounce((value) => {
        setFilters({
            search: value
        })
    }, 1000);

    const handleSearch = (event) => {
        const value = event.target.value;
        debouncedFunction(value);
    };


    return (
        <div className={styles.formsAndAffiliatesOuterCont}>
            <Tabs
                onChange={handleChange}
                type="card"
                defaultActiveKey="1"
            >
                <Tabs.TabPane 
                    tab={<span className={styles.TabPaneTitle}>Forms</span>}
                    key="1"
                    animated
                >

                    <div className={styles.documentCont}>{
                        <Row>
                            <Row style={{ width: "100%",  flexWrap: "nowrap", justifyContent: "center", gap: 5 }}>
                                <Col className={styles.searchInputBoxCol}>
                                    <div className={styles.searchInputBox}>
                                        <Form form={form} initialValues={{}}>
                                            <Form.Item name="search">
                                                <Input
                                                    allowClear
                                                    placeholder="Search here..."
                                                    suffix={<SearchOutlined />}
                                                    onChange={handleSearch}
                                                    size="middle"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Col>
                                <Col>
                                    <div
                                        className={styles.sortBox}
                                    >
                                        <Button
                                            style={{
                                                background: "#3a74b6",
                                                color: "whitesmoke",
                                                fontWeight: "bold",
                                                border: "none",
                                                padding: "0 10px"
                                            }}
                                            onClick={handleClear}
                                        >
                                            <div>Reset</div>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {
                                getDoraDocumentsLoading ? 
                                <div style={{height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Spin size="large" />
                                </div> :
                                    (getDoraDocumentsError ? "" :
                                        <Row className={styles.documentScroll}>
                                            <Col
                                                span={24}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    overflowY: "auto",
                                                    paddingLeft: "10px",
                                                }}
                                            >
                                                <Tree>
                                                    {totratlast()?.length ?
                                                        totratlast().map((item) => {
                                                            if (item.doc_second.length > 0) {
                                                                return (
                                                                    <Tree.Folder key={item._id} name={item.name} style={{ cursor: "pointer" }} getTransactionLaneData={getTransactionLaneData} fiters={fiters}>
                                                                        {item.doc_second.map((element) => {
                                                                            return <Tree.File key={element._id} doraDocItem={element} selectedData={getIndividualTransactionData} seller={seller} getTransactionLaneData={getTransactionLaneData} insideFolder={true} section={section}/>;
                                                                        })}
                                                                    </Tree.Folder>
                                                                );
                                                            } else {
                                                                return <Tree.File key={item._id} doraDocItem={item} selectedData={getIndividualTransactionData} seller={seller} getTransactionLaneData={getTransactionLaneData} insideFolder={false} section={section} />;
                                                            }
                                                        }) : ""}
                                                </Tree>
                                            </Col>
                                        </Row>
                                    )
                            }
                            
                        </Row>
                    }
                    </div>

                    
                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span className={styles.TabPaneTitle}>Affiliates</span>}
                    key="2"
                    animated
                >
                    <Collapse
                        ghost
                        expandIcon={({ isActive }) => <div style={{verticalAlign: "middle"}}><CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: "1.3rem" }} /></div>}
                        onChange={handleChange}
                        activeKey={activeKey}
                    >

                        {((getIndividualTransactionData?.status === "ACCEPTED") || (getIndividualTransactionData?.status === "TERMINATED") || (getIndividualTransactionData?.status === "DRAFT") || (getIndividualTransactionData?.status === "SUBMITTED")) &&
                            <Panel
                                style={{ marginTop: "10px", border: "1px solid #cccccc", borderRadius: "10px" }}
                                key="2"
                                expandIcon={({ isActive }) => <div style={{verticalAlign: "middle"}}><CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: "1.3rem" }} /></div>}
                                header={
                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} onClick={(e) => e.stopPropagation()}>
                                        <span
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                marginLeft: ".3rem",
                                                color: "#096dd9",
                                            }}
                                        >
                                            Title Company
                                        </span>
                                        <span>
                                            <PlusCircleFilled
                                                {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: handleOpen})}
                                                style={{ fontSize: "25px", color: "#085190" }}
                                            />
                                        </span>
                                    </div>
                                }
                            >
                                <div style={{ minHeight: "250px" }}>
                                    {
                                        getTitleCompanyDataLoading ? <div style={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Spin size="large" />
                                        </div> : (
                                            getTitleCompanyDataError ? "" : (
                                                getTitleCompanySelectedData && getTitleCompanySelectedData?.titleCompanyName && (
                                                    <div style={{ padding: "6px 18px 18px", display: "flex", flexDirection: 'column', color: "gray" }} >
                                                        <div
                                                            style={{
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {
                                                                getTitleCompanySelectedData?.titleCompanyLogo &&
                                                                <img
                                                                    src={getTitleCompanySelectedData?.titleCompanyLogo}
                                                                    style={{
                                                                        height: "60px",
                                                                        width: "80px",
                                                                        objectFit: "contain",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                            <div className={styles.titleCompanyDetailCont}>
                                                                <table className={styles.titleCompanyDetailTable}>
                                                                    {
                                                                        getTitleCompanySelectedData?.titleCompanyName &&
                                                                        <tr>
                                                                            <td className={styles.label}>Company</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleCompanyName}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleOfficeName &&
                                                                        <tr>
                                                                            <td className={styles.label}>Office</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeName}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleOfficeAddress &&
                                                                        <tr>
                                                                            <td className={styles.label}>Office Address</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeAddress}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleOfficeEmail &&
                                                                        <tr>
                                                                            <td className={styles.label}>Office Email</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeEmail}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleOfficePhoneNo &&
                                                                        <tr>
                                                                            <td className={styles.label}>Office Phone</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficePhoneNo}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleOfficeFaxNo &&
                                                                        <tr>
                                                                            <td className={styles.label}>Office Fax No.</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeFaxNo}</td>
                                                                        </tr>
                                                                    }

                                                                    {
                                                                        getTitleCompanySelectedData?.salesRepName &&
                                                                        <tr>
                                                                            <td className={styles.label}>Sales Rep</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.salesRepName ? getTitleCompanySelectedData?.salesRepName : ""}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.salesRepEmail &&
                                                                        <tr>
                                                                            <td className={styles.label}>Sales Rep Email</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.salesRepEmail ? getTitleCompanySelectedData?.salesRepEmail : ""}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.salesRepPhone &&
                                                                        <tr>
                                                                            <td className={styles.label}>Sales Rep Phone</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.salesRepPhone ? getTitleCompanySelectedData?.salesRepPhone : ""}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleCloserName &&
                                                                        <tr>
                                                                            <td className={styles.label}>Title Closer</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleCloserName}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleCloserEmail && 
                                                                        <tr>
                                                                            <td className={styles.label}>Title Closer Email</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleCloserEmail}</td>
                                                                        </tr>
                                                                    }
                                                                    {
                                                                        getTitleCompanySelectedData?.titleCloserPhone && 
                                                                        <tr>
                                                                            <td className={styles.label}>Title Closer Phone</td>
                                                                            <td className={styles.value}>{getTitleCompanySelectedData?.titleCloserPhone}</td>
                                                                        </tr>
                                                                    }
                                                                </table>
                                                            </div>
                                                        <div style={{ textAlign: "center", maxWidth: "100%", marginTop: "10px", borderRadius: "8px", }}>
                                                            <Button
                                                                type="primary"
                                                                disabled={((seller === "SELLER" && getTitleCompanySelectedData?.selectedBy === "SELLER_AGENT") || (seller === "BUYER" && getTitleCompanySelectedData?.selectedBy === "BUYER_AGENT")) ? false : true}
                                                                {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: handeldeleteTitleCompanydata })}
                                                            >
                                                                Clear
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                style={{ marginLeft: "5px" }}
                                                                disabled={!getTitleCompanySelectedData?.titleCloserEmail && !getTitleCompanySelectedData?.titleOfficeEmail && !getTitleCompanySelectedData?.salesRepEmail}
                                                                onClick={handleInnerClick}
                                                            >
                                                                Send
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )
                                    }
                                </div>
                            </Panel>
                        }
                    </Collapse>

                </Tabs.TabPane>


                
            </Tabs>
       
            {
                showcompanymodal && <TitleCompanyDetails
                    section={section}
                    setShowcompanymodal={setShowcompanymodal}
                    selectedStatus={seller}
                />
            }
            {
                sendCompanyModal && <TitleCompanyDocument selectedStatus={seller} selectedData={getIndividualTransactionData} setSendCompanyModal={setSendCompanyModal} section={section}/>
            }
        </div>
    );
}
