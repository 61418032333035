/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState,useRef, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import '../styles/customInput.css'; 
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { Button, Form, Image, Modal, Collapse, Input, message, Tooltip } from "antd";
import { Checkbox } from "antd"
import debounce from 'lodash/debounce';
import EmailTemplateModal from "../../Common/components/EmailTemplateModal";
import ContentEditable from "../../Common/components/ContentEditable";
import { PropertyHandlers } from "../state/actions";
import AddEmailModal from "./AddEmailModal";
import { mapClientsForSendEmail } from "../utils/helper";
import DocumentList from "../../Rtd/component/DocumentList";
import { Select } from 'antd';
import { accountHandle } from "../../Account/state/actions";
import { EyeOutlined, DeleteOutlined ,ExclamationCircleOutlined} from "@ant-design/icons";
import axios from "axios";
import { ClientList } from "../../Client/components/ClientList";
import { generateFullNameFromObj, validateEmail } from "../../Common/utils/extraFunctions";
import { useMediaQuery } from "react-responsive";
import { TiContacts } from "react-icons/ti";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import RecipientList from "../../Common/components/RecipientList";
import { Loading } from "../../Common/components/Loading";
import {  getcombinedClients, mapClientDocs, } from "./helper";
import styles from "../styles/EmailModal.module.css"
import SubjectLine from "./SubjectLine";
import moment from "moment";
import confirm from "antd/lib/modal/confirm";
const { Option } = Select;

// This is the new design in send document
const EmailModal = ({
    role,
    client,
    source,
    section,
    ispdfClone,
    documentId,
    selectedData,
    contractType,
    signerChecked,
    initialMountRef,
    setSignerChecked,
    isEmailModalVisible,
    currentPropertyDocId,
    setNestedModalOpen,
    nestedModalOpen,
    delegateOwnerId,
    RTDInitiator,
    newState,
    setNewState,
    reloadSection,
    componentUsedSection,
    showTextArea,
    setShowTextArea,
   allBuyerAgentData,
  allSellerAgentData,
    setPropertyDocsModalOpen,
    setSelectedClient,
    setPage,
    page,
     }) => {
      const delegate = (section === "DELEGATE");
      // const currentAgentId = selectedData?.currentAgentId;
  const {propertyDocsWithoutSection,propertDocsWithoutSectionLoading, filterSort} = useSelector((state) => state.PropertyReducer);
  const { personDocs ,personDocsLoading,personDocsError} = useSelector((state) => state.client.personDetailReducer);
  const [personsArray, setPersonsArray] = useState([]);
  const [Email_templatevisible, setEmail_templatevisible] = useState(false);
  const [selectedtext, setSelectedText] = useState("");
  const [emailSignatureId, setEmailSignatureId] = useState({});
  const [ispreview, setIspreview] = useState(false);
  const [value, setValue] = useState("");
  // const [page, setPage] = useState([1]);
  const [isfilterArchive, setIsFilterArchive] = useState("");
  const [showClientListModal, setShowClientListModal] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [emailList, setEmailList] = useState({
    emailMessage: "",
  });
  const [agents, setAgents] = useState(false);
 let payloadData = {};
  const { clickedItem } = currentPropertyDocId || {};
  const initialState = {
    documentSelected: [],
    clientDocumentSelected:[],
    propertyDocsSelected: [],
   sharedSelected: [],
   documentWithoutBuyerSelected: []
  };
  const [data,setData] = useState(initialState);
  const { emailSignData, emailSignLoading } = useSelector(
    (state) => state.account,
  );

  const { getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  

  const history = useHistory();
  const {pathname} = useLocation();
  const breakPoint = useMediaQuery({ maxWidth: "570px" })

  const getClientDocName = (data,name) => {
    let {contractType, transactionData = {}} = data;
    switch (contractType) {
      case 'ERTL':
      case 'ETCT':
      case 'ERTB': {
        let startDate = transactionData?.ListingPeriodBeginDate
          ? moment(transactionData?.ListingPeriodBeginDate).format("MM/DD/YYYY, h:mm A")
          : '';
        let endDate = transactionData?.ListingPeriodEndByDate
          ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A")
          : '';
        return (
          startDate && endDate ?`${name} ${startDate} - ${endDate} `:
          startDate?`${name} ${startDate}`:
          endDate?`${name} ${endDate}`:
          `${name}`
        )
      }
      case 'LCAE': 
      case 'LCAES_ETCT': 
      case 'LCAES_ERTL':{
        let endDate = transactionData?.ListingPeriodEndByDate
          ? moment(transactionData?.ListingPeriodEndByDate).format("MM/DD/YYYY, h:mm A")
          : '';
        return (endDate ?`Amend/Extend -  ${endDate}`:`Amend/Extend`);
      }
  
      default:
        let dateTime = data?.createdAt
          ? moment(data?.createdAt).format("MM/DD/YYYY, h:mm A")
          : '';
        return `${name} ${dateTime}`;
    }
  };

  const processClientDocs = (docsTabDoraDocs=[]) => {
    let clientDocuments = [];
    docsTabDoraDocs?.forEach((doc) => {
      if (doc.createdDocuments?.length > 0) {
        doc.createdDocuments.forEach((subDoc) => {
          subDoc.contractName = getClientDocName(subDoc, doc?.name);
          clientDocuments.push(subDoc);
          if (subDoc.matchedDocuments?.length > 0) {
            subDoc.matchedDocuments.forEach((matchDocument) => {
              matchDocument.contractName = getClientDocName(matchDocument);
              matchDocument.isSecondLevel = true;
              clientDocuments.push(matchDocument);
            });
          }
        });
      }
    });
    return clientDocuments;
  };
  
 
  useEffect(() => {
      const defaultSignVal = emailSignData?.find(sign => sign.isDefault === true) || { isDefault: false, _id: '' };
      setEmailSignatureId({ ...defaultSignVal });

    return () => {
      setEmailSignatureId({ checked: false, value: '' });
    };
  }, [emailSignData]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [subjectForm] = Form.useForm();
  const { Panel } = Collapse;

  const handleEmail_templateCancel = () => {
    setEmail_templatevisible(false);
  };

  const handleInput = (event) => {
    setEmailList({
      ...emailList,
      emailMessage: event.target.innerHTML,
    });
  };

  const handleselectemail = (value) => {
    <div dangerouslySetInnerHTML={{ __html: selectedtext }} />;
    setSelectedText(value);
    setEmailList({
      ...emailList,
      emailMessage: value,
    });
    setEmail_templatevisible(false);
  };

  const combinedClientsData = useMemo(() => {
    let clientDocs = [];
  if (source === "CLIENT_DOC") {
    clientDocs = mapClientDocs(personDocs);
  }
    const data = getcombinedClients({
      allContractDocuments: source=== "CLIENT_DOC" ? clientDocs : propertyDocsWithoutSection || [],
      condition: role === "BUYER" ? "BUYER" : "SELLER",
      propertySellerIds: role==="SELLER" && source !== "CLIENT_DOC" ? getIndividualTransactionData?.sellerIds : [],
    });
    return {
      combinedBuyerIds: data.combinedBuyerIds,
      combinedSellerIds: data.combinedSellerIds,
    };
  }, [propertyDocsWithoutSection, getIndividualTransactionData, role]);
  

useEffect(() => {
  let tempArr = [];
  switch (source) {
    case "SELLING_DRAFT":
    case "SELLING_INPROGRESS":
    case "SELLING_RTD":
      tempArr = mapClientsForSendEmail({ clients: combinedClientsData.combinedSellerIds, role: "SELLER" });
      break;
    case "PROPERTY_DOCS":
      tempArr = role === "BUYER" ? mapClientsForSendEmail({ clients: combinedClientsData.combinedBuyerIds, role: "BUYER" }) : mapClientsForSendEmail({ clients: combinedClientsData.combinedSellerIds, role: "SELLER" });
      break;
    case "BUYING_DRAFT":
    case "BUYING_RTD":
      tempArr = mapClientsForSendEmail({ clients: combinedClientsData.combinedBuyerIds, role: "BUYER" });
      break;
    case "CLIENT_DOC":
      tempArr = role === "BUYER" ? mapClientsForSendEmail({ clients: combinedClientsData.combinedBuyerIds, role: "BUYER" }) : mapClientsForSendEmail({ clients: combinedClientsData.combinedSellerIds, role: "SELLER" });
      break;
    default:
      tempArr = selectedData?.acceptedOfferSellers?.map((seller) => ({
        openFor: "SELLER",
        seller: seller?.fullName || null,
        key: seller?._id || null,
        name: seller?.fullName || null,
        emailPresent: !!seller?.email,
        email: seller?.email || null,
      }));
      break;
  }
  setPersonsArray(tempArr);
  const initialChecked = {};
  tempArr?.forEach((client) => {
    initialChecked[client._id] = { checked: !!client.personId?.email && validateEmail(client?.personId?.email), isReadOnly: client.isReadOnly, isAddedByUser: false, row: client };
    if (client.isCorp) {
      client?.signers?.forEach((signer) => {
        initialChecked[signer._id] = { checked: !!signer.personId?.email && validateEmail(signer?.personId?.email), isReadOnly: client.isReadOnly, isAddedByUser: false, row: signer };
      });
    }
  });
  setSignerChecked(initialChecked);
  return () => {
    initialMountRef.current = false;
  };
}, [selectedData, source, role, propertyDocsWithoutSection]);

  let unmounted = {
    value: false
  };
  let sourceAxios = axios.CancelToken.source();

useEffect(() => {
  dispatch(accountHandle.getEmailSignData({unmounted, source:sourceAxios}));

  return () => {
          unmounted.value = true;
          sourceAxios.cancel("Cancelling in cleanup");
          setData({
            documentSelected: [],
            propertyDocsSelected: [],
            sharedSelected: [],
            documentWithoutBuyerSelected: []
          });
        }
}, []);

  useEffect(() => {
    if (!initialMountRef.current && Object.keys(signerChecked)?.length > 0) {
      handleSelectAllChange(true);
      initialMountRef.current = true;
    }
  }, [signerChecked, isEmailModalVisible]);

  const handleEmailCancel = () => {
    setNestedModalOpen({...nestedModalOpen,isEmailModalVisible:false})
    setEmailList({
      emailMessage: "",
    });
    setSelectedText("");
    setSignerChecked({});
    if(source==="PROPERTY_DOCS"){
      setPropertyDocsModalOpen({propertyDocs:false})
    }
  };
const handleSetAgents=(value)=>{
  
 setAgents(value)
}

  function processNestedDataforReadOnly(data) {
    let results = [];

    const isMongoDBObjectID = id => /^[a-f\d]{24}$/i.test(id);

    if (data && data.length) {
      data.forEach(item => {
        if (!item?.isCorp && item?.isReadOnly) {
          if (item.personId?._id && isMongoDBObjectID(item.personId?._id)) {
            // Only send personId if it's a valid MongoDB ObjectID
            results.push({ personId: item.personId?._id });
          } else {
           
            results.push({
              ...(item.personId.fullName && { fullName: item.personId?.fullName }),
              ...(item.personId.email && { email: item.personId?.email })
            });
          }
        } else if (item?.isCorp && item?.signers?.length > 0) {
          // Handle the case for corps
          item.signers.forEach(signer => {
            if (signer.isReadOnly) {
              if (signer.personId?._id && isMongoDBObjectID(signer.personId?._id)) {
                results.push({ personId: signer.personId?._id });
              } else {
                results.push({
                  ...signer.personId?.email && { email: signer.personId.email },
                });
              }
            }
          });
        }
      });
    }

    return results;
  }



  const handleSend = () => {
    const Role=role
    const subjectValue = subjectForm?.getFieldValue("subject");
      const arr = Object.values(signerChecked)
      .filter((el) => el.checked && !el?.isReadOnly && !el?.isAddedByUser)
      .map((el) => ({
         ...(el?.row?.personId?._id ? {clientId: el?.row?.personId?._id}:{}),
         ...(el?.row?.openFor ? {openFor: el?.row?.openFor}:{}),
         ...(el?.row?.isCorp ? {key: el?.row?.corpKey} : el?.row?.personId?._id ? {key:el?.row?.personId?._id}:{}),
        })).filter(el => Object.keys(el).length !== 0);


    const readonlyClients = processNestedDataforReadOnly(personsArray);

    if(nestedModalOpen?.sendMultipleDoc){

        let params={
        clientArray: arr,
          ...(readonlyClients?.length ? { readOnlyEmailsArray: readonlyClients } : {}),
          ...(data?.documentSelected.length ? { documentIds: [...data?.documentSelected] }: {}),
          ...(data?.sharedSelected.length ? { sharedFolderDocsArray: [...data?.sharedSelected] }: {}),
          ...((data?.propertyDocsSelected.length || data?.documentWithoutBuyerSelected.length) ? { 
            propertyDocsArray: [
              ...(data?.propertyDocsSelected || []), 
              ...(data?.documentWithoutBuyerSelected || [])
            ] 
          } : {}),
          ...(data?.clientDocumentSelected?.length ? { contactDocArray: [...data?.clientDocumentSelected] }: {}),
          ...(emailSignatureId?.isDefault && emailSignatureId?._id ? { emailSignatureId: emailSignatureId?._id }: {}),
          sentBy: role === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT",
          
          ...(emailList.emailMessage ?
            {emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1),} : {}),
          ...(delegate ? { delegateSide: role === "BUYER" ? "BUYER" : "SELLER", delegateOwnerId: selectedData?.currentAgentId?._id } : {}),
          ...(subjectValue?.length ? {subject: subjectValue} : {}),
    };
    if (RTDInitiator ) {
        params = {
        ...params,
          ...(getIndividualTransactionData?.thirdpartyId ? { thirdpartyIdDoc: getIndividualTransactionData?.thirdpartyId }: {}),
          ...(getIndividualTransactionData?.offerId ? { offerId: getIndividualTransactionData?.offerId } : {}),
          ...(getIndividualTransactionData?.persistId ? { ertsId: getIndividualTransactionData?.persistId } : {}),
          ...(getIndividualTransactionData?.propertyId ? { propertyId: getIndividualTransactionData?.propertyId } : {}),
          ...(subjectValue?.length ? {subject: subjectValue} : {}),
        };
      } else {
        params = {
            ...params,
          ...(selectedData?.thirdpartyId ? { thirdpartyIdDoc: selectedData?.thirdpartyId } : {}),
          ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
          ...(selectedData?.persistId ? { ertsId: selectedData?.persistId } : {}),
          ...(selectedData?.propertyId ? { propertyId: selectedData?.propertyId } : {}),
          ...(subjectValue?.length ? {subject: subjectValue} : {}),
        };
      }
       
       if(agents&&params?.propertyDocsArray?.length>0&&((role === "BUYER" && allSellerAgentData?.length)||(role === "SELLER" && allBuyerAgentData?.length))){
        const modal = confirm({
         title: (
      <div className={styles.confirmTitle} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>
          Are you sure you want to send the selected document{params?.propertyDocsArray?.length > 1 ? "s" : ""} to the following agent{((role === "SELLER" && allBuyerAgentData.length > 1) || (role === "BUYER" && allSellerAgentData.length > 1)) ? "s" : ""}?
        </span>
        <span
          style={{
            position: 'absolute',
            top: '2px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '25px',
             color:'grey',
            borderRadius: '50%',
            padding: '5px',
          }}
          onClick={() => modal.destroy()}
        >
          x
        </span>
      </div>
    ),

          okText: "Yes",
          cancelText: "No",
          width: 700,
          icon: <ExclamationCircleOutlined />,
          content:<div style={{ marginTop: "5px",maxWidth: "700px", overflowX: "auto",maxHeight:"450px",overflowY:"auto", whiteSpace: "nowrap" }}>{RTDInitiator === "SELLER" || role === "SELLER" ? <RecipientList data={allBuyerAgentData} type="buyer" /> : <RecipientList data={allSellerAgentData} type="seller" />}</div>,
          onOk() {
            const updatedParams = {
              ...params,
              ...(role === "BUYER" && allSellerAgentData?.length > 0 && agents ? { sendToAgent: "SELLER_AGENT" } : {}),
              ...(role === "SELLER" && allBuyerAgentData?.length > 0  && agents ?{ sendToAgent: "BUYER_AGENT" } : {}),
            };
          dispatch(PropertyHandlers.SendMultipleDoc({data:updatedParams,offerId: getIndividualTransactionData?.offerId, role: Role, unmounted, source, Role, delegateOwnerId:delegate && getIndividualTransactionData?.currentAgentId?._id,agents:true }));
          },
          onCancel() {
            
            if(arr.length<=0 &&readonlyClients.length<=0){
              message.error("Please add at least one recipient.")
            }else{
            dispatch(PropertyHandlers.SendMultipleDoc({data:params,offerId: getIndividualTransactionData?.offerId, role: Role, unmounted, source, Role, delegateOwnerId:delegate && getIndividualTransactionData?.currentAgentId?._id,agents:false }));
          }},
          
        });
        
   }
   else{
    dispatch(PropertyHandlers.SendMultipleDoc({data:params}));
   }
  }

    else {
        const roleSpecificPayload = role === "SELLER" ? { sentBy: "SELLER_AGENT", offerId: selectedData?.offerId } : { sentBy: "BUYER_AGENT", offerId: selectedData?._id };
      payloadData = {
        ...roleSpecificPayload,
        clientArray: arr,
        documentId,
        ...(readonlyClients.length ? { readOnlyEmailsArray: readonlyClients } : {}),
        ...(selectedData?.persistId ? { ertsId: selectedData.persistId } : {}),
        ...(selectedData?.thirdpartyId?.length > 0 ? { thirdpartyIdDoc: selectedData.thirdpartyId } : {}),
        ...(role === "SELLER" ? { offerId: selectedData.offerId } : { offerId: selectedData?._id }),
        ...(emailList.emailMessage ? { emailMessage: emailList.emailMessage.charAt(0).toUpperCase() + emailList.emailMessage.slice(1) } : {}),
        ...(delegate ? { delegateSide: role === "BUYER" ? "BUYER" : "SELLER", delegateOwnerId: selectedData?.currentAgentId?._id } : {}),
        ...((emailSignatureId?.isDefault && emailSignatureId?._id) ? {emailSignatureId:emailSignatureId?._id}:{})
      };
      dispatch(transactionLaneDataHandle.sendEmailToClients(payloadData));
    }
    handleEmailCancel();
   
  };

  const successFunc = ({reducerFunc}) => {
    if(componentUsedSection === "TRANSACTION_DASHBOARD"){
      if(reducerFunc){
        dispatch(reducerFunc());
      }
    }
    else{
      history.push({
        pathname,
        state: {
          category: role,
          categoryId: selectedData?.offerId
        }
      })
    }
  }

const handleSelectAllChange = (checked) => {
    const updatedSignerChecked = {};

    const updatedClientsArray = personsArray.map((seller) => {
      if (!seller.isCorp && seller.personId?.email && validateEmail(seller?.personId?.email)) {
        updatedSignerChecked[seller._id] = { checked, isAddedByUser:seller?.isAddedByUser, row: seller };
        // Preserve isReadOnly if isAddedByUser is true
        return seller.isAddedByUser ? { ...seller, isReadOnly: true } : { ...seller };
      } else if (seller.isCorp) {
        const updatedSigners = seller?.signers.map((signer) => {
          if (signer?.personId?.email && validateEmail(signer?.personId?.email)) {
            updatedSignerChecked[signer._id] = { checked, isAddedByUser:signer?.isAddedByUser, row: signer };
            // Preserve isReadOnly if isAddedByUser is true
            return signer.isAddedByUser ? { ...signer, isReadOnly: true }: { ...signer };
          }
          return signer;
        });
        return { ...seller, signers: updatedSigners };
      }
      return seller;
    });

    setSignerChecked(updatedSignerChecked);
    setPersonsArray(updatedClientsArray);
  };

const handleClientsCheckboxChange = (sellerId, checked) => {
    setSignerChecked((prev) => ({
      ...prev,
      [sellerId]: {
        checked,
        isReadOnly:checked,
        isAddedByUser: prev[sellerId]?.isAddedByUser,
        row: prev[sellerId]?.row,
      },
    }));

    setPersonsArray((prev) =>
      prev.map((client) =>
        client._id === sellerId ? { ...client, isReadOnly: checked } : client
      )
    );
  };


  const CorporationCheckbox = ({ corporation = {} }) => {
    const [isCorpChecked, setIsCorpChecked] = useState(() => {
      const isInitiallyChecked = corporation?.signers?.every((signer) => signer?.personId?.email && validateEmail(signer?.personId?.email) && signerChecked[signer._id]?.checked);
      return isInitiallyChecked || false;
    });

    const handleCheckboxChange = (signerId, checked) => {
      setSignerChecked((prev) => ({
        ...prev,
        [signerId]: { checked,  isReadOnly: checked, row:prev[signerId]?.row },
      })
    );

    setPersonsArray((prevData) =>
        prevData.map((client) => {
        if (client?.signers && client?.signers?.length > 0) {
            const updatedSigners = client?.signers?.map((signer) =>
            signer._id === signerId
                ? { ...signer, isReadOnly: false }
                : signer
            );
            return { ...client,isReadOnly: false, signers: updatedSigners };
        }

        return client;
        })
    );

      if (corporation?.signers) {
        // Checking if all child checkboxes are checked
        const allChildChecked = corporation.signers.every((signer) => signer?.personId?.email && validateEmail(signer?.personId?.email) && signerChecked[signer._id]?.checked);
        // Updating the state of the parent checkbox
        setIsCorpChecked(allChildChecked);
      }
    };


    const handleParentCheckboxChange = (checked) => {
      setIsCorpChecked(checked);
      if (corporation?.signers) {
        corporation?.signers?.forEach((signer) => {
          if (signer?.personId?.email && validateEmail(signer?.personId?.email)) {
            setSignerChecked((prev) => ({
              ...prev,
              [signer._id]: { checked,isReadOnly:false, row: prev[signer._id]?.row },
            }));
          }
        });
      }

     setPersonsArray(prev=>
        prev.map((client)=>{
            if(client?.signers && client?.signers?.length > 0){
                const updatedSigners = client.signers.map((signer)=>({...signer, isReadOnly:false}));
                return { ...client,isReadOnly:false, signers: updatedSigners };
            }
            return client
        })
     )
    };


    const handleUpdateReadOnlyCorp = (signerId, checked) => {
        const updatedReadOnly = checked;

        setPersonsArray((prevData) =>
            prevData.map((client) => {
            if (client?.signers && client?.signers?.length > 0) {
                const updatedSigners = client?.signers?.map((signer) =>
                signer._id === signerId
                    ? { ...signer, isReadOnly: updatedReadOnly }
                    : signer
                );
                return { ...client,isReadOnly: updatedReadOnly, signers: updatedSigners };
            }

            return client;
            })
        );

        setSignerChecked((prev) => ({
          ...prev,
          [signerId]: { checked:true, row: prev[signerId]?.row, isReadOnly: checked },
        }));
      };



    return (
      <div>
    
        <Checkbox checked={isCorpChecked} onChange={(e) => handleParentCheckboxChange(e.target.checked)}>
        <text className={styles.coorporationfullName}> {corporation?.fullName || ""} {"Signers"}</text>
        </Checkbox>
        {corporation?.signers?.map((signer) => (
          <div style={breakPoint ? {marginLeft: "10px", display: "flex", alignItems: "center", fontSize: "10px"}:
          { marginLeft: "20px", display: "flex", alignItems: "center", whiteSpace: "nowrap", fontSize: "18px" }} key={signer?._id}>
            <Checkbox checked={signerChecked[signer._id]?.checked} onChange={() => handleCheckboxChange(signer._id, !signerChecked[signer._id]?.checked)} disabled={!signer?.personId?.email || !validateEmail(signer?.personId?.email)} style={{ marginTop: "0px" }} />
            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px",}}>
            
              <span className={styles.fullName}>{signer?.fullName}</span>
              <span className={styles.dash}>-</span>
              <span className={styles.email}>
                {signer.personId?.email && validateEmail(signer.personId?.email) ? (
                  <span className={styles.email}>
                   {signer.personId.email}
                    <span style={breakPoint?{marginLeft:"5px"}:{marginLeft:"10px"}}>
                        <Checkbox  checked={signerChecked[signer._id]?.isReadOnly}
                          onChange={(event)=>handleUpdateReadOnlyCorp(signer._id, event.target.checked)}>
                            <span className={styles.readOnly}>Read Only</span>
                        </Checkbox>
                    </span>
                </span>
                ) : (
                  <AddEmailModal offerId={selectedData?.offerId ? selectedData?.offerId : getIndividualTransactionData?.offerId} clientData={signer} successFunc={successFunc} role={role} delegate={delegate} selectedData={selectedData} breakPoint={breakPoint} source={source} filterSort={filterSort} setPage={setPage} setSelectedClient={setSelectedClient} setNestedModalOpen={setNestedModalOpen} nestedModalOpen={nestedModalOpen}/>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };
const NormalPersonDetails = ({ person }) => {
  return (
    <>
      <>
        <Checkbox checked={signerChecked[person._id]?.checked} onChange={() => handleClientsCheckboxChange(person?._id, !signerChecked[person?._id]?.checked)} style={{ fontSize: "20px", marginRight: "10px" }} disabled={!person?.personId?.email || !validateEmail(person?.personId?.email)} />
        <div>
          <span className={styles.fullName}>{person?.fullName}</span>
          {person?.fullName && <span className={styles.dash}>-</span>}
          {person.personId?.email && validateEmail(person.personId?.email) ? (
            <>
              {person.personId?.fullName && person?.isAddedByUser && (
                <>
                  <span className={styles.fullName}> {person?.personId?.fullName} </span>
                  <span className={styles.dash}>-</span>
                </>
              )}

              <span className={styles.email}>
                {person?.personId?.email}
                <span style={breakPoint ? { marginLeft: "5px" } : { marginLeft: "10px" }}>
                  {person?.isAddedByUser ? null : (
                    <Checkbox checked={person?.isReadOnly} onChange={(event) => handleUpdateReadOnly(person, event.target.checked)}>
                      <span className={styles.readOnly}>Read Only</span>
                    </Checkbox>
                  )}
                </span>
              </span>
              <span className={styles.readOnly}>
                <span className={styles.userReadOnly}>{person?.isAddedByUser ? "(Read Only)" : ""}</span>
                <span style={{ cursor: "pointer" }}>
                  {"  "}
                  {person?.isAddedByUser && (
                    <Tooltip title="Delete readonly recipient!" trigger={["hover", "click"]}>
                      <DeleteOutlined onClick={() => handleDeleteReadOnly(person?.personId?._id)} />
                    </Tooltip>
                  )}
                </span>
              </span>
            </>
          ) : (
            <AddEmailModal offerId={selectedData?.offerId ? selectedData?.offerId : getIndividualTransactionData?.offerId} clientData={person} successFunc={successFunc} role={role} delegate={delegate} selectedData={selectedData} breakPoint={breakPoint} source={source} filterSort={filterSort} setPage={setPage} setSelectedClient={setSelectedClient} setNestedModalOpen={setNestedModalOpen} nestedModalOpen={nestedModalOpen}/>
          )}
        </div>
      </>
    </>
  );
};
const handleSelectChange =(id)=>{
   let [filteredValue] = emailSignData?.filter((el)=>el?._id===id).map((el)=>{return {...el, isDefault:true}});
   setEmailSignatureId(filteredValue);
}

const checkClient = (data, selectedClient) => {
    for (const client of data) {
      if (client.isCorp) {
        // If isCorp is true, loop over the signers array
        for (const signer of client.signers) {
          if (signer.personId?._id === selectedClient._id) {
            return true;
          }
        }
      } else {
        // If isCorp is false, compare personId?._id directly
        if (client.personId?._id === selectedClient._id) {
          return true;
        }
      }
    }
    return false;
  };

  // when the user select any client in the clientList
  const handleClientSelected = (selectedClient) => {
    if (!selectedClient?.email) {
      message.error("Please add the client's email to proceed!");
      return;
    }
    const clients = personsArray || [];

    if (clients.length >= 10) {
      message.error(`Maximum number of clients already added.`);
      return;
    }

    let check = checkClient(personsArray, selectedClient);

    if (check) {
      message.error(`User already exists in read only recepients list.`);
      return;
    } else {
      setPersonsArray(
        [...personsArray,
        {
        fullName: generateFullNameFromObj(selectedClient),
        personId:{email:selectedClient?.email, _id:selectedClient._id},
        isCorp: false,
        isReadOnly:true,
        isAddedByUser:true,
        _id:selectedClient._id,
        signers: [],
      }]);

      setSignerChecked(
        {
         ...signerChecked,
         [selectedClient._id]:
        {
        fullName: generateFullNameFromObj(selectedClient),
        personId:{email:selectedClient?.email,},
        isCorp: false,
        isReadOnly:true,
        _id:selectedClient._id,
        signers: [],
        checked:true
      }});
      message.success("Read only recipient added!");
    }
  };



  const handleUpdateReadOnly = (selectedClient, checked) => {
    const updatedReadOnly = checked;

    setPersonsArray((prevData) =>
      prevData.map((client) =>
        client?._id === selectedClient?._id ? { ...client, isReadOnly: updatedReadOnly } : client
      )
    );

    setSignerChecked((prevState) => ({
      ...prevState,
      [selectedClient?._id]: {
        ...prevState[selectedClient?._id],
        isReadOnly: updatedReadOnly,
        isAddedByUser:false,
      },
    }));
  };


  const handleDeleteReadOnly = (id) => {
    let clients = personsArray || [];

    let tobeDelete = clients.find(client => client?.personId?._id === id && client.isReadOnly);
    if (tobeDelete) {
      let updatedClients = clients.filter(client => !(client?.personId?._id === id && client.isReadOnly));

      setPersonsArray(updatedClients);

      let updatedSignerChecked = { ...signerChecked };
      delete updatedSignerChecked[id];
      setSignerChecked(updatedSignerChecked);

      message.success("Read only recipient deleted successfully!");
    } else {
      message.error("Read only recipient not found.");
    }
  };
//add read only

const handleReadOnlyEmailChange = (e) => {
  const value = e.target.value;
    setNewState({ ...newState, email: value });
 };

const handleReadOnlyNameChange = (e) => {
 const value = e.target.value;
  // Filter out empty strings
  setNewState({ ...newState, fullName: value});
  // Update the state with the array of emails
};
const handleEmailAddress = () => {

  // Get single email and name values
  let email = newState && newState?.email && newState?.email?.trim(); // Get email value
  let fullName = newState && newState?.email && newState?.fullName?.trim();   // Get name value
  // Check if email and name are provided
  if (email && fullName) {
    // Generate a unique ID for each email
    let uniqueEmailId = uuidv4().replace(/-/g, "").slice(0, 26);

    let data = {
      personId: {
        email: email,
        fullName: fullName, // Include the name here
        _id: uniqueEmailId,
      },
      isReadOnly: true,
      isAddedByUser: true,
      _id: uniqueEmailId,
    };

    let signerData = {
      checked: true,
      isReadOnly: true,
      isAddedByUser: true,
      row: {
        personId: {
          email: email,
          fullName: fullName, // Include the name here
        },
        _id: uniqueEmailId,
      },
    };

    // Update state
    setPersonsArray((prevArray) => [...prevArray, data]);
    setSignerChecked((prevChecked) => ({ ...prevChecked, [uniqueEmailId]: signerData }));

    // Reset email and name values
    setNewState({ email: '', fullName: '' }); // Reset email and name fields
    form.resetFields();
  }
  setShowTextArea(false)
};


  const isButtonDisabled = () => {
    if (nestedModalOpen?.isAgent) return false;
    const noSignersChecked = Object.values(signerChecked)?.every((el) => !el.checked);
    const noEmailMessage = !emailList?.emailMessage || emailList?.emailMessage === "<p><br></p>";
    const noDocumentsSelected = !(data?.documentSelected?.length || data?.propertyDocsSelected?.length || data?.sharedSelected?.length || data?.clientDocumentSelected?.length || data?.documentWithoutBuyerSelected?.length);
    if (nestedModalOpen?.sendMultipleDoc) {
      if (source === "CLIENT_DOC") {
        return noSignersChecked || (noEmailMessage && noDocumentsSelected);
      }
      return (!agents && noSignersChecked) || (noEmailMessage && noDocumentsSelected);
    } else {
      return noSignersChecked;
    }
  };

  //validate read only emails 
// const validateEmail = (email) => {
//   // email = email; // Remove leading and trailing whitespace
//   const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//   return re.test(email);

// };
  
const clients = personsArray?.filter((client) => !client.isAgent);
// const agents = personsArray?.filter((client) => client.isAgent);
  
return (
  <div style={{ maxHeight: "550px", overflow: "hidden", overflowY: "auto", padding: "5px" }}>
    {/* email signature p[review modal ] */}
    <Modal width={"800px"} visible={ispreview} footer={false} onCancel={() => setIspreview(false)}>
      {emailSignatureId && (
        <div
          style={{
            boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            width: "100%",
            borderRadius: "5px",
            padding: "10px",
            margin: "auto",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <span
                style={{
                  color: "gray",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Name: {emailSignatureId?.title}
              </span>
            </div>
          </div>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              textAlign: "left",
              lineHeight: "0.8",
              marginTop: "5px",
            }}
          >
            {emailSignatureId?.footerText && <div dangerouslySetInnerHTML={{ __html: emailSignatureId.footerText }} />}
          </p>

          <div style={{ display: "flex", justifyContent: "center", minHeight: "300px" }}>{emailSignatureId?.signatureImageUrl && <Image src={emailSignatureId?.signatureImageUrl} alt="Signature" loading="lazy" />}</div>
        </div>
      )}
    </Modal>

    {/* client list modal  */}
    <Modal
      visible={showClientListModal}
      onCancel={() => {
        setShowClientListModal(false);
        setShowTextArea(false);
      }}
      footer={false}
      style={{ marginTop: "-80px" }}
    >
      <ClientList
        listSource={"offerSection"}
        selectClient={handleClientSelected}
        // searchBuyerNameInOffer={input}
        setshowModal={setShowClientListModal}
        page={page}
        setPage={setPage}
        showSearchInput={showSearchInput}
        setShowSearchInput={setShowSearchInput}
        value={value}
        setValue={setValue}
        personsArray={personsArray}
        // isCorpTrue={isCorpTrue}
        // currentlySelectedCorpData={currentlySelectedCorpData}
        delegateOwnerId={delegateOwnerId}
        isfilterArchive={isfilterArchive}
        setIsFilterArchive={setIsFilterArchive}
      />
      <Button
        type="primary"
        style={{ bottom: 0, left: 175, position: "relative", minWidth: "10vw", marginTop: "20px" }}
        onClick={() => {
          setShowClientListModal(false);
          setNewState({ ...newState, isOpen: false, email: "", fullName: "" });
          setShowTextArea(false);
        }}
      >
        Add
      </Button>
    </Modal>

    <EmailTemplateModal Email_templatevisible={Email_templatevisible} setEmail_templatevisible={setEmail_templatevisible} handleEmail_templateCancel={handleEmail_templateCancel} handleselectemail={handleselectemail} />
<div style={{overflowX: "auto",whiteSpace:"nowrap",padding:"10px"}}>
    {(source === "CLIENT_DOC" ? personDocsLoading : propertDocsWithoutSectionLoading) ? (
      <div>
        <Loading />
      </div>
    ) : personDocsError ? (
      ""
    ) : (
      personsArray?.length > 0 && (
        <div style={{ paddingBottom: nestedModalOpen?.sendMultipleDoc ? "0px" : "5px", marginBottom: nestedModalOpen?.sendMultipleDoc ? "20px" : "10px" }}>
          {/* {clients?.length > 0 && <h2 style={{ color: "gray", marginBottom: "-5px" }}> Contacts:</h2>} */}
          <text style={{ fontSize: "large", color: "grey", fontWeight: "bold" }}>{`Recipient${clients?.length > 1 ? "s" : ""}`}:</text>
          {clients?.map((seller) => (
            <div key={seller?._id} style={breakPoint ? { display: "flex", alignItems: "center" } : { display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
              {!seller?.isCorp && <NormalPersonDetails person={seller} />}
              {seller.isCorp && <CorporationCheckbox corporation={seller} />}
            </div>
          ))}
        </div>
      )
    )}

    {showTextArea && (
      <div>
        <Form form={form} onFinish={handleEmailAddress}>
          <div style={{ display: "flex", width: "100%" }}>
            <Form.Item style={{ display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={() => setShowClientListModal(true)}>
              <Tooltip title="Add Existing Contact" trigger={["click", "hover"]}>
                <TiContacts style={{ fontSize: "35px" }} />
              </Tooltip>
            </Form.Item>

            <Form.Item name="fullName" style={{ width: "30%", marginRight: "8px" }}>
              <div className="customForm">
                <Input rows={1} placeholder="Enter Name" onChange={handleReadOnlyNameChange} value={newState.fullName || ""} style={{ resize: "none", width: "100%" }} />
              </div>
            </Form.Item>

            <Form.Item
              name="email"
              rules={[{ required: true, type: "email", message: "Please enter a valid email address" }]}
              style={{ width: "90%", marginRight: "8px" }} // Larger proportion for the email box
            >
              <div className="customForm">
                <Input placeholder="Enter email address" onChange={handleReadOnlyEmailChange} value={newState.email || ""} type="email" style={{ resize: "none", width: "100%" }} />
              </div>
            </Form.Item>
            <Form.Item
              name="email"
              style={{ width: "15%" }} // Larger proportion for the email box
            >
              <div>
                <Button type="primary" htmlType="submit" disabled={!newState?.email || !newState?.fullName}>
                  Add
                </Button>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    )}
    {(RTDInitiator === "SELLER" || role === "SELLER") && allBuyerAgentData?.length > 0 && (
      <div style={{ display: "flex", fontWeight: "bold", color: "grey", fontSize: "18px",marginTop:"-18px" }}>
        <Checkbox checked={agents} onChange={(e) => handleSetAgents(e.target.checked)}>
          <text className={styles.checkBox}> {`Buyer Agent${allBuyerAgentData?.length > 1 ? "s" : ""}`}</text>
        </Checkbox>
      </div>
    )}
    {(RTDInitiator === "BUYER" || role === "BUYER") && allSellerAgentData?.length > 0 && (
      <div style={{ display: "flex", fontWeight: "bold", color: "grey", fontSize: "18px",marginTop:"-18px" }}>
        <Checkbox checked={agents} onChange={(e) => handleSetAgents(e.target.checked)}>
          <text className={styles.checkBox}> {`Seller Agent${allSellerAgentData?.length > 1 ? "s" : ""}`}</text>
        </Checkbox>
      </div>
    )}
    {<div style={{ marginTop: "5px" ,marginBottom:"15px"}}>{RTDInitiator === "SELLER" || role === "SELLER" ? <RecipientList data={allBuyerAgentData} type="buyer" /> : <RecipientList data={allSellerAgentData} type="seller" />}</div>}
</div>
    {/* {agents?.length > 0 && <h2 style={{ color: "gray", marginBottom: "-5px" }}> Agents:</h2>}
          {agents?.map((seller) => (
            <div key={seller?._id} style={breakPoint ? { display: "flex", alignItems: "center" } : { display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
              <NormalPersonDetails person={seller} />
            </div>
          ))} */}

    <div className={styles.addressInput}>
      <SubjectLine form={subjectForm} selectedData={selectedData} data={data} source={source} processClientDocs={processClientDocs} />
    </div>

    {nestedModalOpen?.sendMultipleDoc && (
      <div style={{ marginTop: "-20px", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>
        <Collapse ghost accordion defaultActiveKey={["1"]}>
          <Panel header={<span style={{ fontSize: "15px" }}>Document List</span>} key="1">
            <div>
              <DocumentList documentId={documentId} clickedItem={clickedItem?.documentId} RTDInitiator={RTDInitiator} role={role} nestedModalOpen={nestedModalOpen} data={data} setData={setData} selectedData={selectedData} section={section} reloadSection={reloadSection} screenSource={source} processClientDocs={processClientDocs} />
            </div>
          </Panel>
        </Collapse>
      </div>
    )}
    <ContentEditable setEmail_templatevisible={setEmail_templatevisible} selectedtext={selectedtext} handleInput={handleInput} emailList={emailList} setEmailList={setEmailList} />

    <div style={breakPoint ? { marginTop: "40px" } : { marginTop: "10px" }}>
      <Checkbox checked={emailSignatureId.isDefault} onChange={(e) => setEmailSignatureId({ ...emailSignatureId, isDefault: e.target.checked })}>
        Include Email Signature
      </Checkbox>
      <Select
        value={emailSignatureId._id} // Use value instead of defaultValue
        style={{ width: 200 }}
        loading={emailSignLoading}
        onChange={(value) => handleSelectChange(value)}
        disabled={!emailSignatureId.isDefault}
      >
        {emailSignData &&
          emailSignData?.length &&
          emailSignData?.map((el, ind) => (
            <Option key={ind} value={el?._id}>
              {el?.title}
            </Option>
          ))}
      </Select>
      {emailSignatureId?.isDefault && (
        <Button style={{ fontSize: "16px" }} icon={<EyeOutlined />} onClick={() => setIspreview(true)} type="link">
          Preview
        </Button>
      )}
    </div>

    <div style={{ textAlign: "right", marginTop: "10px" }}>
      <Button type="primary" onClick={handleSend} disabled={isButtonDisabled()}>
        Send
      </Button>
    </div>
  </div>
);
};

export { EmailModal };
