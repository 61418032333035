import React, {useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Button } from "antd";
import { clientAction } from "../state/actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneDetails({ data, selectedClient, setModelDrawer,modelDrawer, section, source }) {
  const dispatch = useDispatch();
  const inputRef = useRef(null)
  const onFinish = (values) => {

    dispatch(clientAction.updatePhone(values, data, selectedClient, section, source));
    setModelDrawer({ status: false, component: "", title: "" });
  };
  const init1 = data?.mobilePhoneNumber?.phoneNumber ? data.mobilePhoneNumber?.phoneNumber : "";

  const init2 = data?.officePhoneNumber?.phoneNumber ? data?.officePhoneNumber?.phoneNumber : "";
    
    
    
  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 15 }}
      initialValues={(source==='officePhoneNumber') ? {officePhoneNumber:init2} : {phoneNumber:init1}}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label={source==='officePhoneNumber' ? "Office Number" : "Mobile Number"}
        name={source==='officePhoneNumber' ? "officePhoneNumber" : "phoneNumber"}
        hasFeedback
        rules={[
          { required: true, message: "" },
          { whitespace: true },
          // ({ getFieldValue }) => ({
          //   validator(_, value) {
          //     if (value?.length === 10) {
          //       return Promise.resolve();
          //     }
          //     return Promise.reject(
          //       new Error("Please Enter 10 digit Phone Number!")
          //     );
          //   },
          // }),
        ]}
      >
        <PhoneInput
          ref={inputRef}
          inputClass="phoneInput"
          country={"us"}
          onlyCountries={["us"]}
          maxLength="10"
          international={false}
          disableCountryCode={true}
          placeholder={source==='officePhoneNumber' ? "Enter Office Phone No." : "Enter Mobile Phone No."}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
}
export { PhoneDetails };
